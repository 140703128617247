import { Ds } from "@accurx/design";
import { Banner } from "../Banner";
import { Sections } from "../Sections";
import { useContent } from "../../context/ContentContext";

export const DefaultLandingPageSection = (): JSX.Element => {
    const { landingPageSettingsContent } = useContent();
    return (
        <>
            <Ds.Text as="h1" size="xxlarge">
                {landingPageSettingsContent.pageTitle}
            </Ds.Text>
            <Banner
                title={landingPageSettingsContent.bannerHeader}
                body={landingPageSettingsContent.bannerBody}
                subtext={landingPageSettingsContent.bannerSubtext}
            />
            <Sections />
        </>
    );
};

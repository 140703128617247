import { CookieFreeTrackEvent, EventDetails } from "@accurx/shared";
import { MapPropertiesClinicianAppIdToUserId } from "./analytics.utils";
import { getCookieBannerResponse } from "@accurx/design";

const isTrackingEnabled = (): boolean =>
    getCookieBannerResponse() === "accepted";

export const trackEvent = (eventDetails: EventDetails): void => {
    const mappedProperties = MapPropertiesClinicianAppIdToUserId(
        eventDetails.properties,
    );

    const fullProps = {
        eventVersion: 5,
        userIsLoggedIn: false,
        ...mappedProperties,
    };

    if (!isTrackingEnabled()) return;

    void CookieFreeTrackEvent({
        object: eventDetails.object,
        objectType: eventDetails.objectType,
        action: eventDetails.action,
        properties: fullProps,
    });
};

import { useRouter } from "next/router";
import { useEffect } from "react";
import { useCreatePatientInitiatedPath } from "../constant/paths";
import { useOrganisation } from "../context/OrganisationContext";
import { useContent } from "../context/ContentContext";

export const useRedirectToBasePageIfPageIsInvalid = () => {
    const { specificFlow } = useContent();
    const { organisationSettings } = useOrganisation();

    const isPageValid =
        specificFlow !== null &&
        specificFlow.flow !== null &&
        organisationSettings !== null;

    const router = useRouter();
    const createPatientInitiatedPath = useCreatePatientInitiatedPath();

    useEffect(() => {
        if (isPageValid) return;
        void router.push(
            createPatientInitiatedPath({
                path: "",
            }),
        );
        // Adding createPatientInitiatedPath to dependencies creates an update loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPageValid, router]);

    return isPageValid;
};

import { OrganisationProvider } from "../../context/OrganisationContext";
import { ContentProvider } from "../../context/ContentContext";
import { AnswerProvider } from "../../context/AnswerContext";
import { PhotoUploadProvider } from "../../context/PhotoUploadContext";
import { ErrorSummaryProvider } from "@accurx/design";
import { AnalyticsProvider } from "../../context/AnalyticsContext";
import { ToastContainer } from "../../../../components/ToastContainer";

type PageLayoutProvidersProps = {
    children?: JSX.Element[] | null;
};

export const PageLayoutProviders = ({
    children,
}: PageLayoutProvidersProps): JSX.Element => {
    return (
        <OrganisationProvider>
            <ToastContainer />
            <ContentProvider>
                <AnalyticsProvider>
                    <AnswerProvider>
                        <PhotoUploadProvider>
                            <ErrorSummaryProvider>
                                {children}
                            </ErrorSummaryProvider>
                        </PhotoUploadProvider>
                    </AnswerProvider>
                </AnalyticsProvider>
            </ContentProvider>
        </OrganisationProvider>
    );
};

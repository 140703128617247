import React, { ComponentProps, ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import { Ds, Link } from "@accurx/design";

export type MarkdownRendererProps = {
    markdown: string;
    overrides?: ComponentProps<typeof ReactMarkdown>["renderers"];
};

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export const HeadingRenderer = ({
    level,
    children,
}: {
    level: HeadingLevel;
    children: ReactNode;
}): JSX.Element => {
    switch (level) {
        case 1:
            return (
                <Ds.Text as="h1" size="xxlarge" weight="bold">
                    {children}
                </Ds.Text>
            );
        case 2:
            return (
                <Ds.Text as="h2" size="xlarge" weight="bold">
                    {children}
                </Ds.Text>
            );
        case 3:
            return (
                <Ds.Text as="h3" size="large" weight="bold">
                    {children}
                </Ds.Text>
            );
        default:
            return (
                <Ds.Text as={`h${level}`} weight="bold">
                    {children}
                </Ds.Text>
            );
    }
};

const MarkdownRenderer = ({
    markdown,
    overrides = {},
}: MarkdownRendererProps): JSX.Element => (
    <ReactMarkdown
        // eslint-disable-next-line react/no-children-prop
        children={markdown}
        renderers={{
            paragraph: ({ children }) => <Ds.Text my="1">{children}</Ds.Text>,
            bold: ({ children }) => <Ds.Text weight="bold">{children}</Ds.Text>,
            link: ({ href, children }) => (
                <Link href={href ?? ""} openInNewTab>
                    {children}
                    <Link.Icon />
                </Link>
            ),
            listItem: ({ children }) => <Ds.Text as="li">{children}</Ds.Text>,
            heading: ({ level, children }) =>
                HeadingRenderer({ level, children }),
            ...overrides,
        }}
    />
);

export { MarkdownRenderer };

import { emergencySymptoms } from "./emergencySymptoms";
import {
    AllowedFileExtensions,
    MaxAllowedFileSizeHumanReadable,
} from "../photoUploadContants";

const formatter = new Intl.ListFormat("en", {
    style: "short",
    type: "disjunction",
});
const humanReadableFileExtensionsList = formatter.format(
    AllowedFileExtensions.map((extension) => extension.toLocaleUpperCase()),
);

export default {
    back: "Back",
    copy: "Copy",
    contactAboutRequest: "Contact us about your request",
    continue: "Continue",
    optional: "optional",
    header: {
        accurxLink: "Powered by Accurx",
    },
    noRadioButtonSelectedError: "Please select an option to continue",
    selectLanguage: "Please select language",
    attachPhotoText: "+ Attach a photo (optional)",
    errorHeading: "There is a problem",

    pages: {
        confirmNotEmergency: {
            title: "Confirm this is not an emergency",
            call999Adult: "Call 999 now if you or someone has any of these:",
            call999Child: "Call 999 now if your child has any of these:",
            signLanguage999: {
                intro: "British Sign Language (BSL) speakers can",
                link: "make a BSL video call to 999",
            },
            speechImpairedContact:
                "Deaf, hard of hearing or speech-impaired people can use 18000 to contact 999 using text relay or a textphone.",
            emergencySymptoms,
            confirmNoSymptoms: "I confirm, none are present",
            tabs: {
                adults: "Adults",
                children: "Children",
            },
        },
        patientDetails: {
            yourDetails: {
                title: "Your details",
                subtitle: "Provide details so we can contact you.",
            },
            patientDetails: {
                title: "Patient details",
                subtitle:
                    "Provide details so we can identify who this request is for.",
            },
            contactDetails: {
                title: "Contact details",
            },
            requestCodeFailure:
                "Something went wrong. Select 'No' to skip this step and continue with your request",
        },
        verification: {
            accept: "Yes, verify",
            decline: "No, skip this step",
        },
        requestType: {
            title: "Who is this request for?",
        },
        review: {
            title: "Review request before submitting",
            subTitle: "Your request will be sent to:",
            query: "Query",
            submitRequest: "Submit request",
            submittingRequest: "Submitting request...",
            contactMethodPreference: "Contact method preference",
            assignRequestToTeam: "Assign request to a team",
            requestSubmittedBy: "Request submitted by",
            submitErrorMessage:
                "Something went wrong submitting this request, please try again",
        },
        confirm2FA: {
            title: "Verification code sent",
            codeSent: "Verification code sent to ",
            receiveTime:
                "It can take up to a minute to send to your mobile phone.",
            confirm: "Confirm verification code",
            sendCode: "Send verification code",
            resendCode: "Didn't receive the code? Try to send it again",
            invalidCode:
                "We were unable to validate this code, please check you have entered the correct code",
            missingCode: "Enter a code",
            unableToSendCode:
                "We were unable to send a new code, please try again",
        },
        landing: {
            nhsLogin: {
                title: "Use your NHS login to submit your request faster.",
                button: "Continue with NHS login",
                moreInformation: {
                    intro: "For issues with NHS login please go to the",
                    link: "NHS login Help Centre",
                },
                successTitle: "You're sharing your NHS login Information",
                manageAccount: {
                    intro: "Manage my",
                    link: "NHS account",
                },
            },
            footer: {
                intro: "Read our",
                link: "security & privacy policy for patients",
                outro: "to find out how Accurx facilitates your care securely and safely.",
            },
        },
        reception: {
            title: "Welcome to the reception flow",
            formFeedbackSummary:
                "Use this form to submit a patient triage request on behalf of a patient",
            learnMore: "Learn more about the reception flow",
            create: "Create a triage request for:",
            dateOfBirth: "Date of birth:",
            phoneNumber: "Phone number:",
            postCode: "Postcode:",
            failedToFindPatient:
                "Failed to find patient details. Please try to start the request again from the Accurx Desktop inbox",
            confirmPatientDetails: "Confirm patient details are correct.",
        },
        confirmation: {
            requestSubmitted: "Request submitted!",
            whatHappensNext: "What happens next?",
            receptionSubmissionMessageTitle:
                "This request is now in the Accurx inbox.",
            receptionSubmissionMessage:
                "If you want to add more information to this request, this can be done by adding a note in Accurx inbox. You can also save the request to your clinical system.",
            submissionMessage:
                "A member of the team will review this request within 0",
            feedbackFormTitle:
                "How was your experience with this request form?",
            feedbackFormText:
                "Accurx created this form and regularly seeks feedback to improve it. Click here to share your thoughts with them.",
            confirmationTextSentProxy:
                "A confirmation text has been sent to proxyNum",
            confirmationTextError:
                "Sorry, something went wrong sending you a confirmation text",
            defaultResponseTime: "2 working days",
            refId: "Your ref ID",
            trackingInfoText:
                "Make a note of the reference number below and share with your healthcare provider if there are any technical issues with your request.",
            linkCopied: "Link copied to clipboard",
            trackingAriaLabel: `Copy request tracking id ID`,
            learnMoreRefId: "Learn more about this reference number.",
            copiedToClipboard: "Copied to clipboard",
            problemCopyingToClipboard:
                "There was a problem copying to clipboard",
        },
    },
    emergencyInformationBanner: {
        title: "In an emergency do not use this form as your request will not be seen immediately.",
    },
    photoUpload: {
        pageTitle: "Add a photo",
        title: "Attach Photo",
        subtitle: "Medical request",
        previewPageTitle: "Preview attached photo",
        errors: {
            fileUploadError:
                "The selected file could not be uploaded. Try again later",
            formatOrSizeErrorHeading:
                "Your file must be in the right format and size",
            fileSizeError: `The selected file must be smaller than ${MaxAllowedFileSizeHumanReadable}`,
            fileEmptyError: "The selected file must not be empty",
            fileExtensionError: `The selected file must be in ${humanReadableFileExtensionsList} format`,
        },
        content: {
            uploadText: "Upload",
            uploadingText: "Uploading",
            attachPhotoText: "+ Attach a photo (optional)",
            patientUploadedText: "uploaded by patient",
            attachments: "Attachments",
            oneImageLeft: "You have 1 image left",
            multipleImagesLeft: "You have 5 images left",
            deletePhoto: "Delete photo",
            tipsTitle: "Tips for taking a good photo:",
            tipsLight: "Make sure you have adequate lighting",
            tipsClear:
                "Make sure the subject is clear and in-focus (the image has crisp edges)",
            tipsScale:
                "Where appropriate place a ruler or coin in the photo to provide a sense of scale",
            legalTitle: "Please note:",
            notContaining:
                "Photos should not include intimate areas of the body (e.g. bottom, breasts, or genitals)",
            staffDisclaimer: "Photos may be seen by staff of all genders",
            saveToRecord:
                "Photos and files may be saved to your medical record",
            moreInformation: `For more information about sending photos and files via Accurx and how they are stored click [here](${`https://support.accurx.com/en/articles/8957474-patient-support-sending-photos-and-files-to-your-healthcare-provider-via-accurx`}).`,
            agreeToTerms: "I have read and agree to the above",
        },
    },
    selectAnOption: "Select an option",
    questions: {
        characterRemaining: "character remaining",
        charactersRemaining: "characters remaining",
        typeResponse: "Type response here",
        addOptionPlaceholder: "Type medication here",
        remove: "Remove",
        addAnother: "Add another",
        selectAllThatApply: "Select all that apply",
    },
    recipients: {
        myself: "Myself",
        someoneElse: "Someone else",
        patient: "Patient",
    },
    singleLink: {
        seeOtherOptions: "See other available options",
    },
    translationModal: {
        title: "Automated Translation",
        body: "This form was translated using Microsoft Azure, so the accuracy can't be guaranteed. If you are concerned about using automated translation or need an interpreter, please contact your GP practice.",
        understandButton: "I understand",
    },
} as const;

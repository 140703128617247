import { createContext, ReactNode, useContext, useState } from "react";
import { EventUserType } from "@accurx/shared/dist/Analytics/AnalyticsConstants";
import {
    TriageBaseProps,
    TriageDirectLinkBaseProps,
} from "../analytics/PatientInitiatedEvents";
import { useOrganisation } from "./OrganisationContext";

export const DEFAULT_BASE_ANALYTICS = {
    anonymousRequestId: "",
    isReceptionFlow: false,
    eventUserType: EventUserType.Patient,
    loginType: null,
    clinicianAppId: null,
    userOrganisationId: 0,
};

const DEFAULT_SL_BASE_ANALYTICS: TriageDirectLinkBaseProps = {
    anonymousRequestId: "",
    userOrganisationId: 0,
};

export type AnalyticsContextState = {
    baseAnalytics: TriageBaseProps;
    setBaseAnalytics: (baseAnalytics: TriageBaseProps) => void;
    specificLinkBaseAnalytics: TriageDirectLinkBaseProps | null;
};

export const defaultState: AnalyticsContextState = {
    baseAnalytics: DEFAULT_BASE_ANALYTICS,
    setBaseAnalytics: (_analyticsProps: TriageBaseProps) => {},
    specificLinkBaseAnalytics: DEFAULT_SL_BASE_ANALYTICS,
};

export const AnalyticsContext =
    createContext<AnalyticsContextState>(defaultState);

export const useAnalytics = (): AnalyticsContextState => {
    const context = useContext(AnalyticsContext);
    // If we're on the client-side, ensure that anything trying to use the useAnalytics hook is wrapped in the provider
    if (typeof window !== "undefined" && context === undefined) {
        throw new Error(
            "This component is an Analytics context consumer, it must be used within a AnalyticsProvider",
        );
    }

    return context;
};

export const AnalyticsProvider = ({
    children,
    initialContextState = {},
}: {
    children: ReactNode;
    initialContextState?: Partial<AnalyticsContextState>;
}): JSX.Element => {
    const [baseAnalytics, setBaseAnalytics] = useState<TriageBaseProps>(
        DEFAULT_BASE_ANALYTICS,
    );

    const { organisationSettings } = useOrganisation();

    const setSpecificLinkBaseState = () => {
        if (!organisationSettings) return null;
        return {
            userOrganisationId: organisationSettings?.organisationId ?? 0,
            anonymousRequestId: organisationSettings?.requestTrackingId ?? "",
        };
    };

    return (
        <AnalyticsContext.Provider
            value={{
                baseAnalytics,
                setBaseAnalytics,
                specificLinkBaseAnalytics: setSpecificLinkBaseState(),
                ...initialContextState,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};

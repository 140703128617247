import {
    ToastContainer as ReactToastifyContainer,
    ToastContainerProps,
} from "react-toastify";
import styled from "styled-components";

export const ToastContainer = styled(ReactToastifyContainer).attrs(
    (props: ToastContainerProps) => {
        return {
            position: "top-center",
            hideProgressBar: true,
            draggable: true,
            closeButton: false,
            ...props,
        };
    },
)`
    &&&.Toastify__toast-container {
        width: 100%;
        padding: 10px;
    }

    .Toastify__toast {
        padding: 0;
        min-height: auto;
        background: none;
    }

    .Toastify__toast > * {
        width: 100%;
    }
`;

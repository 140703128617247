import { createContext, ReactNode, useContext, useState } from "react";
import { OrganisationSettings } from "../api/organisation";
import { createOrganisationSettings } from "../api/organisation/factory/organisationSettings";
import { ReceptionFlowProvidedPatientDetails } from "../api/reception";
import { createReceptionFlowResponse } from "../api/reception/factory/receptionPatientDetailsFlow";
import { Option } from "@accurx/design";
import { ProvidedPatientDetails } from "../api/patient";

export type OrganisationContextState = {
    organisationSettings: OrganisationSettings | null;
    setOrganisationSettings: (
        organisationSettings: OrganisationSettings,
    ) => void;
    receptionPatientDetails: ReceptionFlowProvidedPatientDetails | null;
    setReceptionPatientDetails: (
        receptionPatientDetails: ReceptionFlowProvidedPatientDetails | null,
    ) => void;
    organisationAssignee: Option | null;
    setOrganisationAssignees: (orgAssignees: Option | null) => void;
    providedPatientDetails: ProvidedPatientDetails | null;
    setProvidedPatientDetails: (
        providedPatientDetails: ProvidedPatientDetails | null,
    ) => void;
    resetOrgState: () => void;
};

export const defaultState: OrganisationContextState = {
    organisationSettings: createOrganisationSettings(),
    receptionPatientDetails: createReceptionFlowResponse(),
    organisationAssignee: null,
    providedPatientDetails: null,
    setReceptionPatientDetails: () => {},
    setOrganisationSettings: (
        _organisationSettings: OrganisationSettings,
    ) => {},
    setOrganisationAssignees: () => {},
    setProvidedPatientDetails: () => {},
    resetOrgState: () => {},
};

export const OrganisationContext =
    createContext<OrganisationContextState>(defaultState);

export const useOrganisation = (): OrganisationContextState => {
    const context = useContext(OrganisationContext);

    // If we're on the client-side, ensure that anything trying to use the useOrganisation hook is wrapped in the provider
    if (typeof window !== "undefined" && context === undefined) {
        throw new Error(
            "This component is a Organisation context consumer, it must be used within a OrganisationProvider",
        );
    }

    return context;
};

export const OrganisationProvider = ({
    children,
    initialContextState = {},
}: {
    children: ReactNode;
    initialContextState?: Partial<OrganisationContextState>;
}): JSX.Element => {
    const [organisationSettings, setOrganisationSettings] =
        useState<OrganisationSettings | null>(null);
    const [receptionPatientDetails, setReceptionPatientDetails] =
        useState<ReceptionFlowProvidedPatientDetails | null>(null);
    const [organisationAssignee, setOrganisationAssignees] =
        useState<Option | null>(null);
    const [providedPatientDetails, setProvidedPatientDetails] =
        useState<ProvidedPatientDetails | null>(null);

    const resetOrgState = () => {
        setOrganisationAssignees(null);
        setReceptionPatientDetails(null);
        setProvidedPatientDetails(null);
    };

    return (
        <OrganisationContext.Provider
            value={{
                organisationSettings,
                setOrganisationSettings,
                receptionPatientDetails,
                setReceptionPatientDetails,
                setOrganisationAssignees,
                organisationAssignee,
                providedPatientDetails,
                setProvidedPatientDetails,
                resetOrgState,
                ...initialContextState,
            }}
        >
            {children}
        </OrganisationContext.Provider>
    );
};

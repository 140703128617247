import styled from "styled-components";
import { Tokens } from "@accurx/design";
import { LanguageSelector } from "../LanguageSelector";
import { Header } from "../Header";

export const StyledMain = styled.main`
    max-width: 500px;
    margin: ${Tokens.SIZES[2]} auto 0;
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
    z-index: 1;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        max-width: 570px;
    }
`;

export const StyledHeader = styled(Header)`
    z-index: 2;
`;

export const StyledLanguageSelector = styled(LanguageSelector)`
    width: 250px;
    margin-left: auto;
`;

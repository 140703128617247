import {
    AnalyticsProperties,
    EventUserType,
    EventObjectType,
    EventAction,
} from "@accurx/shared";
import { LandingPageStates } from "../api/organisation";
import { NhsLoginType } from "../types";
import { trackEvent } from "./SharedTrackEvent";

type RequestSubmissionProps = {
    contactMethods: string;
    patientAge: number | null;
    numOfPhotos: number;
    requestedConfirmationSms: boolean;
    isOutOfHours: boolean;
    hasProxy: boolean;
    organisationCode: string;
    landingPageState: LandingPageStates;
    requestType: string;
    requestTopic: string;
    isDefaultAssignee: boolean;
};

type RequestAnalyticsProps = {
    contactMethods: string;
    patientAge: number | null;
    isDefaultAssignee: boolean;
    withConfirmationSMS: boolean;
    symptomsTab: "adults" | "children";
    requestType: string;
    requestTopic: string;
};

export type TriageBaseProps = {
    anonymousRequestId: string;
    isReceptionFlow: boolean;
    eventUserType: EventUserType;
    loginType: NhsLoginType;
    clinicianAppId: string | null;
    userOrganisationId: number;
};

export type TriageFormStartProps = TriageBaseProps & {
    landingPageState: LandingPageStates;
};

export type TriageFormContinue = TriageBaseProps & {
    pageOrigin: string;
};

export type DirectLinkTriageFormContinue = TriageDirectLinkBaseProps & {
    pageOrigin: string;
    requestTopic: string;
    requestType: string;
};

export type TrackTriageFormBaseAnalyticsProps = TriageBaseProps & {
    userOrganisationId: number;
    requestType: string;
    isOutOfHours: boolean;
    hasError: boolean;
    clinicianAppId: string | null;
    requestTopic: string;
};

export type TrackTriageFormSubmitAnalyticsProps =
    TrackTriageFormBaseAnalyticsProps & RequestAnalyticsProps;

export type PatientTriagePanelClickProps = TriageBaseProps & {
    requestType: string;
    panelClicked: string;
};

export type PatientTriageRequestSubmittedProps = TriageBaseProps &
    RequestSubmissionProps;

export type PatientTriageOutOfHoursLinkClickedProps = TriageBaseProps & {
    linkName: string;
};

export type TriageFormLanguageProps = {
    anonymousRequestId: string;
    userOrganisationId: number;
};

const TrackPageViewEvent = (
    object: string,
    customProperties: AnalyticsProperties,
): void => {
    trackEvent({
        object: object,
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: customProperties,
    });
};

const TrackButtonClickEvent = (
    object: string,
    customProperties: AnalyticsProperties,
): void => {
    trackEvent({
        object: object,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: customProperties,
    });
};

const TrackButtonResponseEvent = (
    object: string,
    customProperties: AnalyticsProperties,
): void => {
    trackEvent({
        object: object,
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: customProperties,
    });
};

export const trackTriageFormStartPageView = (
    props: TriageFormStartProps,
): void => {
    TrackPageViewEvent("TriageFormStart", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackPatientInitiatedOutOfHoursPageShown = (
    props: TriageFormStartProps,
): void => {
    TrackPageViewEvent("TriageOutOfHour", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackPatientInitiatedOutOfHoursLinkClicked = (
    props: PatientTriageOutOfHoursLinkClickedProps,
): void => {
    TrackButtonClickEvent("TriageOutOfHour", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormRequestTypeClick = (
    props: PatientTriagePanelClickProps,
): void => {
    TrackButtonClickEvent("TriageFormRequestType", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormRequestTopicClick = (
    props: PatientTriagePanelClickProps,
): void => {
    TrackButtonClickEvent("TriageFormRequestTopic", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormContinue = (props: TriageFormContinue): void => {
    TrackButtonClickEvent("TriageFormContinue", {
        ...props,
        loginType: toPascalCase(props.loginType),
        isDirectLink: false,
        eventUserType: EventUserType.Patient,
    });
};

export const trackDirectLinkTriageFormContinue = (
    props: DirectLinkTriageFormContinue,
): void => {
    TrackButtonClickEvent("TriageFormContinue", {
        ...props,
        isDirectLink: true,
        eventUserType: EventUserType.Patient,
    });
};

export const trackTriageRequestEmergencyPageView = (
    props: TriageBaseProps,
): void => {
    TrackPageViewEvent("TriageFormEmergency", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackPatientClickedNhsLogin = (props: TriageBaseProps): void => {
    TrackButtonClickEvent("TriageNhsLogin", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormSubmitClick = (
    props: PatientTriageRequestSubmittedProps,
): void => {
    TrackButtonClickEvent("TriageFormSubmit", {
        ...props,
        loginType: toPascalCase(props.loginType),
        isDirectLink: false,
    });
};

export const trackTriageFormSubmitResponse = (
    props: TrackTriageFormSubmitAnalyticsProps,
): void => {
    TrackButtonResponseEvent("TriageFormSubmit", {
        ...props,
        loginType: toPascalCase(props.loginType),
        isDirectLink: false,
    });
};

export const trackPatientInitiatedReadPhotoUploadRequirements = (
    props: TriageBaseProps,
): void => {
    TrackButtonClickEvent("TriagePhotoUploadRequirements", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormRedirect = (
    props: TrackTriageFormBaseAnalyticsProps & {
        redirectDestination: string | undefined;
    },
): void => {
    const fullProps = {
        userIsLoggedIn: false,
        ...props,
    };

    trackEvent({
        object: "TriageFormRedirect",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackTriageFormLanguage = (
    props: TriageFormLanguageProps,
): void => {
    trackEvent({
        object: "TriageFormLanguage",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: props,
    });
};

export const trackTriageFormLanguageSelected = (
    props: TriageFormLanguageProps & {
        languageSelected: string | null;
    },
): void => {
    trackEvent({
        object: "TriageFormLanguage",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: props,
    });
};

const toPascalCase = (str: string | null): string | null => {
    if (str === null) {
        return null;
    }
    return (str.match(/[a-zA-Z0-9]+/g) || [])
        .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
        .join("");
};

// Specific Link Events

export type TriageDirectLinkBaseProps = {
    anonymousRequestId: string;
    userOrganisationId: number;
};

export type TriageDirectLinkButtonProps = TriageDirectLinkBaseProps & {
    pageOrigin: string;
};

type TriageDirectLinkProps = TriageDirectLinkBaseProps & {
    isAvailable: boolean;
    isOutOfHours: boolean;
    landingPageState: LandingPageStates;
    pageOrigin: string;
};

export type TrackTriageSpecificLinkFormSubmitAnalyticsProps =
    TriageDirectLinkBaseProps & RequestAnalyticsProps;

export type PatientTriageSpecificLinkRequestSubmittedProps =
    TriageDirectLinkBaseProps & RequestSubmissionProps;

export const trackSpecificLinkTriageFormRedirect = (
    props: TriageDirectLinkBaseProps & {
        redirectDestination: string | undefined;
    },
): void => {
    const fullProps = {
        userIsLoggedIn: false,
        eventUserType: EventUserType.Patient,
        ...props,
    };

    trackEvent({
        object: "TriageFormRedirect",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackTriageDirectLinkFormSubmitClick = (
    props: PatientTriageSpecificLinkRequestSubmittedProps,
): void => {
    TrackButtonClickEvent("TriageFormSubmit", {
        ...props,
        loginType: null,
        isDirectLink: true,
        eventUserType: EventUserType.Patient,
    });
};

export const trackTriageDirectLinkFormSubmitResponse = (
    props: TrackTriageSpecificLinkFormSubmitAnalyticsProps,
): void => {
    TrackButtonResponseEvent("TriageFormSubmit", {
        ...props,
        loginType: null,
        isDirectLink: true,
        eventUserType: EventUserType.Patient,
    });
};

export const trackDirectLinkEmergencyPageView = (
    props: TriageDirectLinkProps,
): void => {
    TrackPageViewEvent("TriageFormEmergency", {
        ...props,
        loginType: null,
        eventUserType: EventUserType.Patient,
        isDirectLink: true,
    });
};

export const trackTriageDirectLinkUnavailableButtonClick = (
    props: TriageDirectLinkButtonProps,
): void => {
    TrackButtonClickEvent("TriageDirectLinkUnavailable", {
        ...props,
        loginType: null,
        eventUserType: EventUserType.Patient,
    });
};

export const trackDirectLinkPatientOutOfHoursPageShown = (
    props: TriageDirectLinkProps,
): void => {
    TrackPageViewEvent("TriageOutOfHour", {
        ...props,
        isDirectLink: true,
        loginType: null,
        eventUserType: EventUserType.Patient,
    });
};

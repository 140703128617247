import { PageLayoutProviders } from "./PageLayoutProviders";
import {
    StyledHeader,
    StyledLanguageSelector,
    StyledMain,
} from "./PageLayout.styles";
import { Ds } from "@accurx/design";

type PageLayoutProps = {
    children?: JSX.Element | null;
    hasPageStyling?: boolean;
};

/**
 * Providing the base layout for the patient initiated flows. This component
 * should be reused across all patient initiated pages for continuous styling
 */

export const PageLayout = ({
    children,
    hasPageStyling,
}: PageLayoutProps): JSX.Element => {
    return (
        <PageLayoutProviders>
            <StyledHeader />
            {!hasPageStyling ? (
                <StyledMain>
                    <Ds.Flex flexDirection="column" gap={{ xs: "2", md: "3" }}>
                        <StyledLanguageSelector />
                        <Ds.Flex.Item>{children}</Ds.Flex.Item>
                    </Ds.Flex>
                </StyledMain>
            ) : (
                <main>{children}</main>
            )}
        </PageLayoutProviders>
    );
};

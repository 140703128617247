import { useCallback } from "react";
import { useIdleTimerSetup } from "../../../utils/IdleTimer/IdleTimer";
import { useNhsApp } from ".";
import { useRouter } from "next/router";
import { useCreatePatientInitiatedPath } from "../constant/paths";

export const IDLE_TIMEOUT = 630000; // 10mins 30secs

export const useIdleTimer = (): void => {
    const isUsingNhsApp = useNhsApp();
    const router = useRouter();
    const createPatientInitiatedPath = useCreatePatientInitiatedPath();

    const refreshPageOnTimeout = useCallback(() => {
        if (isUsingNhsApp && window.nhsapp?.navigation) {
            window.nhsapp.navigation.goToHomepage();
            return;
        } else {
            void router.push(createPatientInitiatedPath({ path: "" }));
        }
        // Adding createPatientInitiatedPath to dependencies creates an update loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUsingNhsApp, router]);

    useIdleTimerSetup({ onIdle: refreshPageOnTimeout, timeout: IDLE_TIMEOUT });
};

import { useRouter } from "next/router";
import { ParsedUrlQuery, stringify } from "querystring";

export const PATHS = {
    confirmNotEmergency: "/confirm-not-emergency",
    notFound: "/not-found",
    questions: "/questions",
    flows: "/flows",
    recipient: "/recipient",
    patientDetails: "/patient-details",
    photoUploadRequirements: "/photo-upload/requirements",
    photoUploadTips: "/photo-upload/tips",
    photoUploadPreview: "/photo-upload/preview",
    review: "/review",
    confirm2FA: "/confirm-2fa",
    confirmation: "/confirmation",
} as const;

export const useCreatePatientInitiatedPath = () => {
    const { query: routerQuery } = useRouter();
    const { organisationCode: orgCode, slug } = routerQuery;

    return ({
        organisationCode = orgCode,
        specificLinkSlug = slug,
        path,
        query = routerQuery,
    }: {
        organisationCode?: string | string[];
        specificLinkSlug?: string | string[];
        path: string;
        query?: ParsedUrlQuery;
    }) =>
        createPatientInitiatedPath({
            organisationCode,
            specificLinkSlug,
            path,
            query,
        });
};

const createPatientInitiatedPath = ({
    organisationCode,
    specificLinkSlug,
    path,
    query,
}: {
    organisationCode?: string | string[];
    specificLinkSlug?: string | string[];
    path: string;
    query?: ParsedUrlQuery;
}) => {
    if (typeof organisationCode === "string") {
        const patientInitiatedPath = `/patient-initiated/${organisationCode}`;

        if (specificLinkSlug && typeof specificLinkSlug === "string") {
            return `${patientInitiatedPath}/flow/${specificLinkSlug}${path}`;
        }

        return `${patientInitiatedPath}${path}${getQueryString(query)}`;
    }

    return PATHS.notFound;
};

/** @deprecated Use createPatientInitiatedPath instead */
export const getPatientInitiatedRoute = (
    organisationCode: string | undefined | string[],
    path: string,
    query?: ParsedUrlQuery,
): string => {
    if (typeof organisationCode === "string")
        return `/patient-initiated/${organisationCode}${path}${getQueryString(
            query,
        )}`;
    return PATHS.notFound;
};

/** @deprecated Use createPatientInitiatedPath instead */
export const getPatientInitiatedSpecificRoute = (
    organisationCode: string | undefined | string[],
    slug: string | undefined | string[],
    path: string,
): string => {
    if (typeof organisationCode === "string" && typeof slug === "string")
        return `/patient-initiated/${organisationCode}/flow/${slug}${path}`;
    return PATHS.notFound;
};

const getQueryString = (query?: ParsedUrlQuery): string => {
    if (!query) return "";
    const { organisationCode, ...rest } = query;
    const stringified = stringify(rest);
    return stringified.length > 0 ? `?${stringified}` : "";
};
